import "./App.css"
import {getIframe} from "./api";
import {useEffect, useState} from "react";

function App() {
    const [url, setUrl] = useState('');
    const [err, setErr] = useState('');


    useEffect(() => {
        // document.getElementById('iframe-id').addEventListener('click', function(event){
        //     event.preventDefault();
        // });

        const code = window.location.pathname.replace('/', '');
        getIframe(code).then(res => {
            if (20000 !== res.code) {
                setErr(res.msg);
                return;
            }

            const data = res.data;
            if (0 !== data.code) {
                setErr(data.msg);
                return;
            }

            const url = data.data.url;
            setUrl(url);
        })
    }, []);


    const render = ('' !== err ? <p style={{textAlign: "center", color: "red"}}>{err}</p> :
        <iframe id="iframe-id" className="iframe" src={url} sandbox="allow-same-origin allow-scripts allow-popups allow-forms" />);
    return (
        <div className="App">
            {render}
        </div>
    );
}

export default App;
